export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const EXTERNAL_BASE_URL = process.env.REACT_APP_EXTERNAL_BASE_URL;

export const CAPITAL_BASE_URL = process.env.REACT_APP_CAPITAL_BASE_URL;
const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;

export const EXTERNAL_URL = {
  login: `signin?redirectUrl=${REDIRECT_URL}`,
  registration: `register?redirectUrl=${REDIRECT_URL}`,
};

export const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;
