import { CARDS } from "../types";
import api from "../../services/api";
import { enqueueSnackbar } from "../../redux/actions/notistackActions";
import i18next from "../../i18n.config";
import { actionCreator } from "../actionCreator";

export const getUserCardsAction = actionCreator(
  CARDS.GET_USER_CARDS
);

export const getUserCards = () => {
  return async dispatch => {
    dispatch(getUserCardsAction.started());
    api.bankCards
      .getBankCardsList()
      .then(res => dispatch(getUserCardsAction.done(res.data)))
      .catch(error => dispatch(getUserCardsAction.failed(error)));
  };
};

export const createCardAction = actionCreator(
  CARDS.CREATE_CARD
);

export const createCard = data => {
  const formData = new FormData();
  Object.keys(data).forEach(field => {
    formData.append([field], data[field]);
  });
  return async dispatch => {
    dispatch(createCardAction.started());
    api.bankCards
      .createBankCard(formData)
      .then(res => {
        dispatch(createCardAction.done(res.data));
        dispatch(getUserCards());
        dispatch(
          enqueueSnackbar({
            message: i18next.t("Card successfully created"),
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              autoHideDuration: 3000,
            },
          })
        );
      })
      .catch(error => dispatch(createCardAction.failed(error)));
  };
};

export const setDefaultCardAction = actionCreator(
  CARDS.SET_DEFAULT_CARD
);

export const setDefaultCard = card_id => {
  return async dispatch => {
    dispatch(setDefaultCardAction.started());
    api.bankCards
      .setDefaultCard(card_id)
      .then(res => {
        dispatch(setDefaultCardAction.done(res.data));
        dispatch(getUserCards());
        dispatch(
          enqueueSnackbar({
            message: i18next.t("Default card installed successfully"),
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              autoHideDuration: 3000,
            },
          })
        );
      })
      .catch(error => dispatch(setDefaultCardAction.failed(error)));
  };
};

export const cardsClearError = () => ({
  type: CARDS.CLEAR_ERROR,
});
