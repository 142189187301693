import { RequestStatus } from "../../utils/functions/requestStatuses";
import { CARDS } from "../types";

export default function cards(
  state = {
    data: [],
    error: null,
    createCardRequestStatus: RequestStatus.NOT_ASKED,
  },
  action
) {
  switch (action.type) {
    case CARDS.GET_USER_CARDS.REQUEST:
      return { ...state, data: [], error: null };
    case CARDS.CREATE_CARD.REQUEST:
      return { ...state, error: null, createCardRequestStatus: RequestStatus.LOADING }
    case CARDS.SET_DEFAULT_CARD.REQUEST:
      return { ...state, error: null };

    case CARDS.CREATE_CARD.SUCCESS:
      return { ...state, error: null, createCardRequestStatus: RequestStatus.SUCCEEDED }
    case CARDS.GET_USER_CARDS.SUCCESS:
      return {
        ...state,
        data: action.payload?.sort((a, b) => b.defaultCard - a.defaultCard),
      };

    case CARDS.GET_USER_CARDS.FAILURE:
    case CARDS.SET_DEFAULT_CARD.FAILURE:
      return { ...state, error: action.payload.error };
    case CARDS.CREATE_CARD.FAILURE:
      return { ...state, error: action.payload.error, createCardRequestStatus: RequestStatus.FAILED };

    case CARDS.CLEAR_ERROR:
      return { ...state, error: null };
    default:
      return state;
  }
}
