import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { getTransactions } from "../redux/actions/transactionsActions";
import moment from "moment";
import { ReactComponent as SentIcon } from "../assets/sent.svg";
import { ReactComponent as ReceiveIcon } from "../assets/receive.svg";
import { ReactComponent as ExchangeIcon } from "../assets/exchange.svg";
import SlicedAddress from "../theme/components/SlicedAddress";
import { Button } from "../theme/components/Button";
import DialogWrapper from "../theme/dialogs/DialogWrapper";
import CopyWithAlert from "../utils/CopyWithAlert";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import Pagination from "@material-ui/lab/Pagination";
import TabPanel from "../theme/components/TabPanel";
import History from "./Exchange/History";
import { useIsMobile } from "../utils/hooks/useIsMobile";
import PageWrapper from "../theme/PageWrapper";
import { roundValue } from "../utils/functions/roundValue";
import getStatusColor from "../utils/functions/getStatusColor";
import Breadcrumb from "../theme/components/Breadcrumb";
import { Countdown } from "../theme/components/Countdown";

const format = time => moment(time).startOf("day").format("D MMMM yyyy");

const transactionTypes = {
  receive: "Deposit",
  sent: "Withdrawal",
};

const size = 10;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Transactions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { total } = useSelector(({ transactions }) => transactions);
  const [page, setPage] = useState(0);
  const classes = useTransactionsStyles();

  const transactions = useSelector(({ transactions }) =>
    transactions.data
      .sort((a, b) => moment.utc(b?.create).diff(moment.utc(a?.create)))
      .reduce(
        (res, t) => ({
          ...res,
          [format(t.create)]: [...(res[format(t.create)] || []), t],
        }),
        {}
      )
  );

  const isMobile = useIsMobile();
  const [detail, setDetail] = useState(null);

  useEffect(() => {
    dispatch(getTransactions({ page, size }));
  }, [dispatch, page]);

  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <PageWrapper>
      <Grid item xs={12} md={12} className={classes.breadcrumb}>
        <Breadcrumb to={"/"} pageName={"Wallets"} down={0} />
      </Grid>
      <Paper className={classes.tabContainer}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='simple tabs example'
          indicatorColor='primary'>
          <Tab
            label={isMobile ? t("Transactions") : t("Transactions history")}
            {...a11yProps(0)}
          />
          <Tab
            label={isMobile ? t("Exchanges") : t("Exchange history")}
            {...a11yProps(1)}
          />
        </Tabs>

        <TabPanel value={value} index={0}>
          {Object.keys(transactions).length < 1 ? (
            <Typography variant={"body2"} color={"textSecondary"} align='center'>
              {t("You have no transactions...")}
            </Typography>
          ) : (
            Object.keys(transactions).map(date => (
              <div key={date}>
                <Typography
                  variant={"subtitle2"}
                  color={"textSecondary"}
                  style={{ textAlign: "right", marginBottom: 16 }}>
                  {format() === date ? "Today" : date}
                </Typography>
                {transactions[date].map(transaction => (
                  <Transaction
                    key={transaction?.id}
                    transaction={transaction}
                    setDetail={setDetail}
                  />
                ))}
              </div>
            ))
          )}
          {total > size && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 32,
              }}>
              <Pagination
                count={Math.ceil(total / size)}
                page={page + 1}
                boundaryCount={1}
                siblingCount={1}
                color='primary'
                onChange={(event, page) => setPage(page - 1)}
              />
            </div>
          )}
          <TransactionDialog
            transaction={detail}
            handleClose={() => setDetail(null)}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <History />
        </TabPanel>
      </Paper>
    </PageWrapper>
  );
};

export default Transactions;

export const Transaction = ({ transaction, setDetail }) => {
  const classes = useTransactionsStyles();
  const { t } = useTranslation();
  const marketPrice = useSelector(
    ({ market }) =>
      market?.data?.find(i => i?.ticker === transaction?.ticker)?.price || 0
  );
  const minScale = useSelector(
    ({ wallets }) =>
      wallets?.allTickerSettings?.find(i => i?.ticker === transaction?.ticker)
        ?.minScale
  );

  return (
    <Paper className={classes.root} elevation={4}>
      <Grid container spacing={1} alignItems={"center"} wrap='nowrap'>
        <Grid item xs={8} className={classes.type}>
          <Grid container alignItems={"center"} wrap="nowrap">
            {transaction?.source === "CRYPTONEED_EXCHANGE" ? (
              <ExchangeIcon className={classes.icon} />
            ) : transaction?.transactionType === transactionTypes.sent ? (
              <SentIcon className={classes.icon} />
            ) : (
              <ReceiveIcon className={classes.icon} />
            )}
            <Grid item>
              <Grid container>
                <Typography
                  variant={"body1"}
                  noWrap
                  className={classes.transactionText}
                  style={{ textOverflow: "ellipsis" }}>
                  {transaction?.transactionType === transactionTypes.sent
                    ? t("Withdraw")
                    : t("Receive")}
                  {/* {!isMobile
                  ? transaction?.address.slice(0, 6) + " ..."
                  : // <SlicedAddress address={transaction?.address} />
                "..."} */}
                </Typography>
              </Grid>
              <Grid container wrap="nowrap" style={{ overflow: 'hidden' }}>
                <Typography
                  variant={"subtitle2"}
                  style={{
                    textOverflow: "ellipsis",
                    color:
                      transaction?.transactionStatus &&
                      getStatusColor(transaction?.transactionStatus),
                  }}>
                  {t(transaction?.transactionStatus)}
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.transactionDescriptionWrapper} container direction="column" alignItems="baseline">
              {
                Boolean(transaction?.user) ?
                  <Typography className={classes.tag} variant="body2">
                    {transaction?.user}
                  </Typography> : <span style={{ height: 20 }} />
              }
              {
                Boolean(transaction?.description) ?
                  <Typography
                    variant={"subtitle2"}
                    color="textSecondary"
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: 'nowrap',
                      display: 'block',
                      overflow: 'hidden',
                    }}>
                    {t(transaction?.description)}
                  </Typography> : <span style={{ height: 20 }} />
              }
            </Grid>
          </Grid>
        </Grid>

        <Grid xs={4} item container justifyContent='flex-end' wrap='nowrap'>
          <Grid
            item
            className={clsx(
              classes.amount,
              transaction?.transactionType !== transactionTypes.sent && "sent"
            )}>
            <Box display="flex" flexDirection="row" alignItems="baseline" gridGap={5}>
              <Typography variant={"body1"} className={classes.amountText} noWrap>
                {transaction?.transactionType === transactionTypes.sent && "- "}
                {roundValue(transaction?.amount, minScale)}
              </Typography>
              <Typography variant={"body1"} style={{ fontWeight: 700 }} noWrap>
                {" "}{transaction?.ticker?.toUpperCase()}
              </Typography>
            </Box>
            <Typography variant={"subtitle2"} color={"textSecondary"} noWrap>
              {transaction?.transactionType === transactionTypes.sent && "- "}
              {roundValue(Number(marketPrice * transaction?.amount), 2)} USD
            </Typography>{" "}
          </Grid>
          {Boolean(setDetail) &&
            <Button
              className={classes.btnSmall}
              variant={"contained"}
              onClick={() => setDetail({ ...transaction, marketPrice })}>
              {t("Details")}
            </Button>}
        </Grid>
      </Grid>
    </Paper >
  );
};

export const TransactionInvoice = ({ invoice, onClick, setDetail }) => {
  const classes = useTransactionsStyles();
  const { t } = useTranslation();
  const marketPrice = useSelector(
    ({ market }) =>
      market?.data?.find(i => i?.ticker === invoice?.currency)?.price || 0
  );
  const minScale = useSelector(
    ({ wallets }) =>
      wallets?.allTickerSettings?.find(i => i?.ticker === invoice?.currency)
        ?.minScale
  );

  return (
    <Paper className={clsx(classes.root, classes.invoice)} elevation={4} onClick={() => onClick(invoice)}>
      <Grid container spacing={1} alignItems={"center"} wrap='nowrap'>
        <Grid item xs={8} className={classes.type}>
          <Grid container alignItems={"center"}>
            <SentIcon className={classes.icon} />
            <Grid item zeroMinWidth>
              <Grid container direction="row">
                <Typography
                  variant={"body1"}
                  noWrap
                  className={clsx(classes.transactionText, classes.invoiceTransactionText)}
                  style={{ textOverflow: "ellipsis" }}>
                  {t("Withdrawal Request")}
                </Typography>
                <Typography className={classes.tag} variant="body2">
                  {invoice?.targetUser}
                </Typography>
                <Countdown
                  finishTime={invoice?.finishTime}
                  showFinishText
                />
              </Grid>
              <Typography
                variant={"subtitle2"}
                color="textSecondary"
                style={{
                  textOverflow: "ellipsis"
                }}>
                {t(invoice?.description)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid xs={4} item container justifyContent='flex-end' wrap='nowrap'>
          <Grid
            item
            className={clsx(
              classes.amount,
            )}>
            <Box display="flex" flexDirection="row" alignItems="baseline" gridGap={5}>
              <Typography variant={"body1"} className={classes.amountText} noWrap>
                {roundValue(invoice?.amount, minScale)}
              </Typography>
              <Typography variant={"body1"} style={{ fontWeight: 700 }} noWrap>
                {" "}{invoice?.currency?.toUpperCase()}
              </Typography>
            </Box>
            <Typography variant={"subtitle2"} color={"textSecondary"} noWrap>
              {roundValue(Number(marketPrice * invoice?.amount), 2)} USD
            </Typography>{" "}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const TransactionDialog = ({ transaction, handleClose }) => {
  const classes = useTransactionsStyles();
  const { t } = useTranslation();
  const isSent = transaction?.transactionType === transactionTypes.sent;

  const detailsTransactions = [
    {
      key: "uid",
      label: "User ID",
      isCopy: true,
      isSlice: false,
    },
    {
      key: "id",
      label: "Transactions ID",
      isCopy: true,
      isSlice: false,
    },
    {
      key: "address",
      label: "Address",
      isCopy: true,
      isSlice: true,
    },
    {
      key: "blockhash",
      label: "Block",
      isCopy: true,
      isSlice: false,
    },
    {
      key: "confirmations",
      label: "Confirmations",
      isCopy: true,
      isSlice: false,
    },
    {
      key: "txFee",
      label: "txFee",
      isCopy: true,
      isSlice: false,
    },
    {
      key: "txid",
      label: "txid",
      isCopy: true,
      isSlice: true,
    },
  ];

  return (
    <DialogWrapper
      open={Boolean(transaction)}
      handleClose={handleClose}
      title={t('Transaction details')}>
      <>
        <Typography
          variant={"subtitle2"}
          color={"textSecondary"}
          style={{ marginBottom: 4, textAlign: 'right' }}>
          {moment(transaction?.create).format("DD.MM.yyyy, HH:mm")}
        </Typography>
        <Paper variant={"outlined"} className={clsx(classes.root, classes.borderedRoot)}>
          <Grid
            container
            spacing={1}
            alignItems={"center"}
            wrap='nowrap'
            justifyContent={"space-between"}>
            <Grid item className={classes.type}>
              {transaction?.source === "CRYPTONEED_EXCHANGE" ? (
                <ExchangeIcon className={classes.iconDialog} />
              ) : isSent ? (
                <SentIcon className={classes.iconDialog} />
              ) : (
                <ReceiveIcon className={classes.iconDialog} />
              )}
              <div>
                <Typography
                  variant={"body1"}
                  className={clsx("dialog", classes.transactionText)}
                  color={"textPrimary"}
                  noWrap>
                  {isSent ? t("Send") : t("Deposit")}
                </Typography>
                <Typography variant={"subtitle2"} color={"textSecondary"}
                  style={{
                    textOverflow: "ellipsis",
                    color:
                      transaction?.transactionStatus &&
                      getStatusColor(transaction?.transactionStatus),
                  }}>
                  {t(transaction?.transactionStatus)}
                </Typography>
              </div>
            </Grid>
            <Grid item className={clsx(classes.amount, !isSent && "sent")}>
              <Typography variant={"body1"} style={{ fontWeight: 700 }} noWrap>
                {isSent && "- "}
                {transaction?.amount} {transaction?.ticker.toUpperCase()}
              </Typography>
              <Typography variant={"subtitle2"} color={"textSecondary"} noWrap>
                {isSent && "- "}
                {roundValue(
                  parseFloat(
                    Number(
                      transaction?.marketPrice * Number(transaction?.amount)
                    )
                  ),
                  2
                )}
                USD
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        {transaction &&
          detailsTransactions.map(({ key, label, isSlice }, i) =>
            transaction[key] ? (
              <Grid
                key={key || i}
                container
                spacing={2}
                alignItems={"center"}
                justifyContent={"space-between"}
                style={{ marginTop: 16 }}>
                <Grid item xs={6} zeroMinWidth>
                  <Typography
                    variant={"body1"}
                    color={"textSecondary"}
                    style={{ fontWeight: 400 }}
                    noWrap>
                    {t(label)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  container
                  justifyContent='flex-end'
                  zeroMinWidth>
                  <CopyWithAlert
                    text={transaction[key] + "" || ""}
                    label={label}>
                    <Typography
                      variant={"body1"}
                      color={"textPrimary"}
                      style={{ textAlign: "right", cursor: "pointer" }}
                      noWrap>
                      {isSlice ? (
                        <SlicedAddress address={transaction[key]} short />
                      ) : key === "txFee" ? (
                        `${transaction[key]
                        } ${transaction?.ticker.toUpperCase()}`
                      ) : (
                        transaction[key]
                      )}
                      <CopyIcon
                        style={{
                          fontSize: 13,
                          verticalAlign: "baseline",
                          marginLeft: 3,
                        }}
                        color={"action"}
                      />
                    </Typography>
                  </CopyWithAlert>
                </Grid>
              </Grid>
            ) : null
          )}
      </>
    </DialogWrapper>
  );
};

export const useTransactionsStyles = makeStyles(theme => ({
  root: {
    marginBottom: 12,
    padding: "4px 0",
    border: 'none',
    boxShadow: 'none',
    [theme.breakpoints.down("sm")]: {
      // padding: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 10px 0px 8px",
      marginBottom: 16,
    },
  },
  invoice: {
    border: `1px solid ${theme.palette.border.gold}`,
    padding: '12px 8px',
    marginTop: 20,
    cursor: 'pointer',
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'scale(1.005)',
    }
  },
  tabContainer: {
    background: theme.palette.background.paper,
    padding: '32px 24px',
    marginTop: 0
  },
  breadcrumb: {
    paddingBottom: 32,
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      paddingBottom: 16,
    },
  },
  borderedRoot: {
    border: `1.5px solid #484E5A`,
    padding: '10px 20px',
    marginBottom: 32,
  },
  type: {
    display: "flex",
    alignItems: "center",
  },
  amount: {
    textAlign: "right",
    "&.sent>div>p:first-child": {
      color: theme.palette.success.main,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  details: {
    textAlign: "right",
    textOverflow: "ellipsis",
    "& button": {
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
      },
    },
  },

  btnSmall: {
    "&.MuiButton-contained ": {
      maxHeight: 32,
      minWidth: 96,
      fontWeight: 400,
      color: theme.palette.success.main,
      marginLeft: 30,
      fontSize: 15,
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
        minWidth: 73,
        padding: 0,
      },
      [theme.breakpoints.down(400)]: {
        fontSize: 10,
        minWidth: 65,
        padding: 0,
      },
    },
  },

  icon: {
    height: 20,
    marginRight: 10,
  },
  iconDialog: {
    height: 20,
    marginRight: 10,
  },
  transactionText: {
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  invoiceTransactionText: {
    color: theme.palette.border.gold,
  },
  amountText: {
    fontWeight: 700
  },
  tag: {
    display: 'inline-block',
    background: '#444A55',
    borderRadius: 4,
    fontSize: 10,
    padding: '0 12px',
    marginLeft: 12,
    marginRight: 12,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 350,
  },
  transactionDescriptionWrapper: {
    overflow: 'hidden',
    display: 'inline-block',
    alignSelf: 'end',
  }
}));
