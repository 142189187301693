import React, { useMemo } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import {
  STEP_DOCUMENTS_INFO,
  DOCUMENT_TYPES,
} from "../../../../utils/constants/kyc";
import { snakeCaseToString } from '../../../../utils/functions/string'
import DriverLicenseFrontImage from '../../../../assets/icons/kyc/driver_license_front.svg'
import DriverLicenseBackImage from '../../../../assets/icons/kyc/driver_license_back.svg'
import PassportImage from '../../../../assets/icons/kyc/passport_front.svg'
import IdCardFrontImage from '../../../../assets/icons/kyc/id_card_front.svg'
import IdCardBackImage from '../../../../assets/icons/kyc/id_card_back.svg'

const UploadDocument = ({ state, form }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const imageUrl = useMemo(() => {
    if (!!form[state]) {
      return window.URL.createObjectURL(form[state]);
    } else {
      return "";
    }
  }, [form, state]);

  const imageMockUrl = useMemo(() => {
    if (form?.documentType === DOCUMENT_TYPES.PASSPORT) {
      return PassportImage
    }
    if (state === STEP_DOCUMENTS_INFO.DOCUMENT_AVERS) {
      if (form?.documentType === DOCUMENT_TYPES.ID_CARD) {
        return IdCardFrontImage
      }
      if (form?.documentType === DOCUMENT_TYPES.DRIVER_LICENSE) {
        return DriverLicenseFrontImage
      }
    } else {
      if (form?.documentType === DOCUMENT_TYPES.ID_CARD) {
        return IdCardBackImage
      }
      if (form?.documentType === DOCUMENT_TYPES.DRIVER_LICENSE) {
        return DriverLicenseBackImage
      }
    }
    return ''
  }, [form?.documentType, state])

  return (
    <Grid container justifyContent="center" alignContent="center">
      <Grid item container>
        <Typography
          variant='body1'
          className={classes.title}
          align='flex-start'>
          {t(snakeCaseToString(form?.documentType))}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4} className={classes.wrapper}>
        <div className={classes.descriptionBox}>
          <Typography variant="body2" className={classes.descriptionTitle}>
            {state === STEP_DOCUMENTS_INFO.DOCUMENT_AVERS
              ? t("Upload a photo of the front side of the document")
              : t("Upload a photo of the back document")}
          </Typography>
          {form?.documentType !== DOCUMENT_TYPES.PASSPORT && (
            <Typography
              variant="body2"
              className={clsx(classes.description, classes.descriptionStep)}
            >
              {state === STEP_DOCUMENTS_INFO.DOCUMENT_AVERS
                ? t("step 1 of 2")
                : t("step 2 of 2")}
            </Typography>
          )}
          <Typography variant="body2" className={classes.description}>
            {t(
              "Download a color image of the entire document. Screenshots are not allowed. JPEG or PNG format only. Size is less than 5 MB."
            )}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={4} className={classes.widgetWrap}>
        <div className={clsx(classes.cornerBox, !imageUrl ? {} : classes.withBorder)}>
          <div className={classes.container}>
            {!imageUrl ? (
              <div className={clsx(classes.titleWrap)}>
                <img
                  src={imageMockUrl || ""}
                  alt="document"
                  id="showDocumentImage"
                  className={classes.img}
                />
              </div>
            ) : (
              <div className={clsx(classes.imgWrap)}>
                <img
                  src={imageUrl || ""}
                  alt="document"
                  id="showDocumentImage"
                  className={classes.img}
                />
              </div>
            )}
          </div>
        </div>
      </Grid>
      <Grid item xs={false} sm={false} md={4}></Grid>
    </Grid>
  );
};

export default UploadDocument;
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 400,
    width: '50%',
    borderBottom: `0.02rem solid ${theme.palette.secondary.disabled}`,
    paddingBottom: 8,
    textTransform: 'capitalize',
    marginBottom: 60,
  },
  cornerBox: {
    display: "inline-block",
    width: 240,
    height: 170,
    position: "relative",
    transition: "all .3s ease",
    margin: "10px",
    borderRadius: 20,
  },
  withBorder: {
    border: "1px solid #FFFFFF",
  },
  container: {
    padding: 16,
    width: "100%",
    height: "100%",
    background: theme.palette.background.paper,
    zIndex: 2,
    position: "relative",
    borderRadius: 20
  },

  imgWrap: {
    height: "100%",
    position: "relative",
    margin: 0,
    overflow: "hidden",
  },

  img: {
    display: "block",
    position: "absolute",
    top: "50%",
    left: "50%",
    maxHeight: "100%",
    transform: "translate(-50%, -50%)",
  },

  titleWrap: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  descriptionBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",

    paddingBottom: "24px",
  },
  descriptionTitle: {
    // fontSize: 
    color: theme.palette.text.quaternary,
  },
  description: {
    fontSize: 13,
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "16px",
    whiteSpace: "pre-line",
    padding: "4px 0px",
  },
  descriptionStep: {
    padding: "12px 0px 12px 0px",
    color: theme.palette.success.main,
  },

  widgetWrap: {
    display: "flex",
    justifyContent: "center",
  },

  boxIcons: {
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  },
  icon: {
    width: 122,
    height: 122,
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("xs")]: {
      width: 92,
      height: 92,
    },
  },
}));
