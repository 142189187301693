import moment from "moment";
import { INVEST_PLAN_CYCLE } from "../../../theme/dialogs/CreateAutoInvestPlanDialog/components/InvestPlanCyclesList";
import { INVEST_PLAN_WALLET_TYPE } from "../../../theme/dialogs/CreateAutoInvestPlanDialog/components/WalletTypeSelect";

export function setErrorMessage(field) {
  switch (field) {
    case "firstName":
      return "Please enter first name!";
    case "lastName":
      return "Please enter last name!";
    case "email":
      return "Please enter valid email address!";
    case "authority":
      return "Please enter role!";
    case "login":
      return "Please enter login!";
    case "invalidDate":
      return "Invalid Date Format.";
    case "invalidMaxDate":
      return "Date should not be after maximal date.";
    case "invalidRangeDate":
      return "Date 'from' must precede date 'to'.";
    case "invalidRangeNumber":
      return "Number 'more 'must precede date 'less'.";
    case "passwordRuleShort":
      return "Min 8 chars, upper & lowercase letters, number & symbol.";
    case "passwordRuleShortMobile":
      return "Min 8 chars, upper & lowercase letters, number & symbol.";
    case "currentPassword":
      return "Please enter current password!";
    case "newPassword":
      return "Please enter new password!";
    case "confirmPassword":
      return "Please repeat new password!";
    case "invalidMatchingPassword":
      return "Passwords do not match!";
    case "totpPass":
      return "Please enter a 6-digit verification code.";
    case "cardNumber":
      return "Card number must contain 16 digits.";
    case "cardNumberContain":
      return "Card number must contain 16 digits.";
    case "imageSize":
      return "The file size is large. Please select another photo.";
    case "fileType":
      return "Invalid file type. Refresh your selection.";
    case "maxLength(100)":
      return "No more than 100 characters.";
    case "maxLength(10)":
      return "No more than 10 characters.";
    case "maxLength(20)":
      return "No more than 20 characters.";
    case "twoCharacters":
      return "The field should contain exactly 2 characters"
    case "minimumAmount(0)":
      return "Minimum withdraw amount should be not less then 0";
    case 'maxLength':
      return "No characters left";
    case "onlyLatinLetters":
      return "Only latin letters.";
    case "onlyCyrillicLetters":
      return "Only cyrillic letters"
    case "expirationYearInvalid":
      return "Your card's expiration year is invalid";
    case "phoneNumberInvalid":
      return "The phone number is invalid. Should be in format +380ХХХХХХХХХ";
    case "onlyLetters":
      return "Only letters.";
    case "dob":
      return "You must be of legal age.";
    default:
      return "This is a required field!";
  }
}

const phoneNumberRegex = /(?=.*\+[0-9]{3}\s?[0-9]{2}\s?[0-9]{3}\s?[0-9]{4,5}$)/gm;

export const validatePhoneNumber = (phoneNumber) => {
  // const re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  const re = phoneNumberRegex
  return re.test(phoneNumber);
}

export function changePasswordValidate(name, value, form, isMobile) {
  // required
  const fieldArray = [
    "currentPassword",
    "newPassword",
    "confirmPassword",
    "password",
  ];

  const ALLOWED_PASSWORD_SPECIAL_CHARACTERS =
    " !\"#$%&'()*+,-./:;<=>?@\\[\\\\\\]^_`{|}~";

  const regex = new RegExp(
    `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[${ALLOWED_PASSWORD_SPECIAL_CHARACTERS}])[A-Za-z\\d${ALLOWED_PASSWORD_SPECIAL_CHARACTERS}]{8,49}$`,
    "g"
  );

  if (!value && fieldArray.includes(name)) {
    return {
      invalid: true,
      errorMessage: setErrorMessage("invalidPassword"),
    };
  }

  // passwordRule
  if (
    name !== "currentPassword" &&
    name.toLowerCase().includes("password") &&
    !regex.test(value)
  ) {
    return {
      invalid: true,
      errorMessage: setErrorMessage(
        isMobile ? "passwordRuleShortMobile" : "passwordRuleShort"
      ),
    };
  }

  // matchPasswordOnSecondField
  if (
    (name === "confirmPassword" &&
      !!value &&
      !!(form?.newPassword || form.password) &&
      (form?.newPassword || form.password) !== value) ||
    (name === ("newPassword" || "password") &&
      !!value &&
      !!form?.confirmPassword &&
      form?.confirmPassword !== value)
  ) {
    return {
      invalid: true,
      errorMessage: setErrorMessage("invalidMatchingPassword"),
    };
  }

  // default
  return {
    invalid: false,
    errorMessage: "",
  };
}

export function validatePassword(name, value, form) {
  // passwordRule
  const regex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$");

  if (name.toLowerCase().includes("password") && !regex.test(value)) {
    return {
      invalid: true,
      errorMessage: setErrorMessage("passwordRuleShort"),
    };
  }

  // default
  return {
    invalid: false,
    errorMessage: "",
  };
}

export function requiredValidator(_, value) {
  // required
  if (typeof value !== "boolean" && !value) {
    return {
      invalid: true,
      errorMessage: "This is a required field!",
    };
  }

  // default
  return {
    invalid: false,
    errorMessage: "",
  };
}

export function autoInvestPlanValidator(name, value, form) {
  // required

  const requiredFields = [
    'planName',
    'amountPerPeriod',
    'coinAllocationTo',
    'coinInvestedFrom',
    'recurringCycle',
  ]
  const requiredTimePlanCycles = [
    INVEST_PLAN_CYCLE.DAILY,
    INVEST_PLAN_CYCLE.MONTHLY,
    INVEST_PLAN_CYCLE.WEEKLY
  ]
  if (name === 'addressToDistributeReward' && form?.['walletType']?.value === INVEST_PLAN_WALLET_TYPE.MONEYBOX && !value) {
    return {
      invalid: true,
      errorMessage: "This is a required field!",
    }
  }
  if (name === 'dayOfMonth' && form?.['recurringCycle']?.value === INVEST_PLAN_CYCLE.MONTHLY && !value) {
    return {
      invalid: true,
      errorMessage: "This is a required field!",
    }
  }
  if (name === 'dayOfWeek' && form?.['recurringCycle']?.value === INVEST_PLAN_CYCLE.WEEKLY && !value) {
    return {
      invalid: true,
      errorMessage: "This is a required field!",
    }
  }
  if (name === 'time' && !form?.['isOneTimeInvest'] && requiredTimePlanCycles.includes(form?.['recurringCycle']?.value) && !value) {
    return {
      invalid: true,
      errorMessage: "This is a required field!",
    }
  }
  if (name === 'timezone' && !form?.['isOneTimeInvest'] && !value) {
    return {
      invalid: true,
      errorMessage: "This is a required field!",
    }
  }
  if (requiredFields.includes(name) && typeof value !== "boolean" && !value) {
    return {
      invalid: true,
      errorMessage: "This is a required field!",
    };
  }
  if(name === 'planName' && value && value?.length > 10) {
    return {
      invalid: true,
      errorMessage: setErrorMessage('maxLength(10)')
    }
  }

  // default
  return {
    invalid: false,
    errorMessage: "",
  };
}

export function withdrawMoneyboxBalanceValidator(name, value, form) {
  // required

  const requiredFields = [
    'amount',
  ]
  if (requiredFields.includes(name) && !value) {
    return {
      invalid: true,
      errorMessage: "This is a required field!",
    }
  }
  if (name === 'amount' && value && Number(value) === 0) {
    return {
      invalid: true,
      errorMessage: setErrorMessage('minimumAmount(0)')
    }
  }
  if (name === 'description' && value && value?.length > 200) {
    return {
      invalid: true,
      errorMessage: setErrorMessage('maxLength')
    }
  }

  // default
  return {
    invalid: false,
    errorMessage: "",
  };
}

export function newMoneyboxValidator(name, value, form) {
  // required

  const requiredFields = [
    'name',
    'description',
  ]
  if (requiredFields.includes(name) && !value) {
    return {
      invalid: true,
      errorMessage: "This is a required field!",
    }
  }
  if (name === 'name' && value && value?.length > 10) {
    return {
      invalid: true,
      errorMessage: setErrorMessage('maxLength(10)')
    }
  }
  if (name === 'description' && value && value?.length > 20) {
    return {
      invalid: true,
      errorMessage: setErrorMessage('maxLength(20)')
    }
  }

  // default
  return {
    invalid: false,
    errorMessage: "",
  };
}

export function newCompanyValidator(name, value, form) {
  // required

  const requiredFields = [
    'name',
    'shortName',
  ]
  if (requiredFields.includes(name) && !value) {
    return {
      invalid: true,
      errorMessage: "This is a required field!",
    }
  }
  if (name === 'name' && value && value?.length > 50) {
    return {
      invalid: true,
      errorMessage: setErrorMessage('maxLength')
    }
  }
  if (name === 'shortName' && value && (value?.length > 2 || value?.length < 2)) {
    return {
      invalid: true,
      errorMessage: setErrorMessage('twoCharacters')
    }
  }
  if (name === 'description' && value && value?.length > 200) {
    return {
      invalid: true,
      errorMessage: setErrorMessage('maxLength')
    }
  }

  // default
  return {
    invalid: false,
    errorMessage: "",
  };
}

export function validateInput(name, value, form, isFilter = false) {
  const validEmailRegex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  // const validOwnerCardRegex = new RegExp(/^[a-zA-Z_+]+$/);
  const validOwnerCardRegex = new RegExp(/^[\u0400-\u04FF]+$/);

  const luhn = (value) => {
    let nCheck = 0;
    if (value && /[0-9-\s]+/.test(value)) {
      value = value.replace(/\D/g, "");

      value.split("").forEach((v, n) => {
        let nDigit = parseInt(v, 10);

        if (!((value.length + n) % 2) && (nDigit *= 2) > 9) {
          nDigit -= 9;
        }

        nCheck += nDigit;
      });
    }
    return nCheck % 10 === 0;
  };

  // required

  const fieldArrayRequired = [
    "firstName",
    "lastName",
    "birthDay",
    "birthMonth",
    "birthYear",
    "gender",
    "authorities",
    "email",
    "username",
    "password",
    "login",
    "address",
    "cardName",
    "cardNumber",
    "ownerFirstName",
    "ownerLastName",
    "ownerMiddleName",
    "amount",
    "expirationMonth",
    "expirationYear",
    "phoneNumber",
  ];

  if (!value && !isFilter && fieldArrayRequired.includes(name)) {
    return {
      invalid: true,
      errorMessage: setErrorMessage(name),
    };
  }
  // maxLength 100

  if (
    (value + "").length > 100 &&
    !isFilter &&
    fieldArrayRequired.includes(name)
  ) {
    return {
      invalid: true,
      errorMessage: setErrorMessage(name),
    };
  }
  // cardNumber
  if (
    name === "cardNumber" &&
    !(
      form[name].replaceAll(" ", "").length < 16 ||
      luhn(form[name].replaceAll(" ", ""))
    )
  ) {
    return {
      invalid: true,
      errorMessage: setErrorMessage(name),
    };
  }

  // email
  if (name === "email" && !validEmailRegex.test(value)) {
    return {
      invalid: true,
      errorMessage: setErrorMessage(name),
    };
  }

  // ownerCard
  if (
    (name === "ownerFirstName" ||
      name === "ownerLastName" ||
      name === "ownerMiddleName") &&
    !validOwnerCardRegex.test(value)
  ) {
    return {
      invalid: true,
      errorMessage: setErrorMessage("onlyCyrillicLetters"),
    };
  }

  // date
  if (
    (name === "dateFrom" || name === "dateTo") &&
    value &&
    !moment(value).isValid()
  ) {
    return {
      invalid: true,
      errorMessage: setErrorMessage("invalidDate"),
    };
  }

  // maxDate
  if ((name === "dateFrom" || name === "dateTo") && moment().isBefore(value)) {
    return {
      invalid: true,
      errorMessage: setErrorMessage("invalidMaxDate"),
    };
  }

  // numberRangeValidator
  if (
    (name === "amountMore" &&
      !!value &&
      !!form?.amountLess &&
      +form?.amountLess < value) ||
    (name === "amountLess" &&
      !!value &&
      !!form?.amountMore &&
      +form?.amountMore > value) ||
    (name === "totalMore" &&
      !!value &&
      !!form?.totalLess &&
      +form?.totalLess < value) ||
    (name === "totalLess" &&
      !!value &&
      !!form?.totalMore &&
      +form?.totalMore > value)
  ) {
    return {
      invalid: false,
      errorMessage: setErrorMessage("invalidRangeNumber"),
    };
  }

  // dateRangeValidator
  if (
    (name === "dateTo" &&
      !!value &&
      !!form?.dateFrom &&
      !moment(form?.dateFrom).isBefore(value)) ||
    (name === "dateFrom" &&
      !!value &&
      !!form?.dateTo &&
      !moment(value).isBefore(form?.dateTo))
  ) {
    return {
      invalid: true,
      errorMessage: setErrorMessage("invalidRangeDate"),
    };
  }

  if (name === 'expirationYear' && !!value && value < +String(new Date().getFullYear()).slice(-2)) {
    return {
      invalid: true,
      errorMessage: setErrorMessage("expirationYearInvalid"),
    };
  }

  if (name === 'phoneNumber' && !validatePhoneNumber(value)) {
    console.log('phone', value)
    return {
      invalid: true,
      errorMessage: setErrorMessage("phoneNumberInvalid"),
    };
  }

  // default
  return {
    invalid: false,
    errorMessage: "",
  };
}

export function validate(name, value, form, isFilter = false) {
  const validEmailRegex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  // required
  const fieldArray = [
    "firstName",
    "lastName",
    "authorities",
    "email",
    "username",
    "password",
    "login",
    "address",
    "cardName",
  ];

  if (!value && !isFilter && fieldArray.includes(name)) {
    return {
      invalid: true,
      errorMessage: setErrorMessage(name),
    };
  }

  // email
  if (name === "email" && !validEmailRegex.test(value)) {
    return {
      invalid: true,
      errorMessage: setErrorMessage(name),
    };
  }

  // date
  if (
    (name === "dateFrom" || name === "dateTo") &&
    value &&
    !moment(value).isValid()
  ) {
    return {
      invalid: true,
      errorMessage: setErrorMessage("invalidDate"),
    };
  }

  // maxDate
  if ((name === "dateFrom" || name === "dateTo") && moment().isBefore(value)) {
    return {
      invalid: true,
      errorMessage: setErrorMessage("invalidMaxDate"),
    };
  }

  // numberRangeValidator
  if (
    (name === "amountMore" &&
      !!value &&
      !!form?.amountLess &&
      +form?.amountLess < value) ||
    (name === "amountLess" &&
      !!value &&
      !!form?.amountMore &&
      +form?.amountMore > value) ||
    (name === "totalMore" &&
      !!value &&
      !!form?.totalLess &&
      +form?.totalLess < value) ||
    (name === "totalLess" &&
      !!value &&
      !!form?.totalMore &&
      +form?.totalMore > value)
  ) {
    return {
      invalid: false,
      errorMessage: setErrorMessage("invalidRangeNumber"),
    };
  }

  // dateRangeValidator
  if (
    (name === "dateTo" &&
      !!value &&
      !!form?.dateFrom &&
      !moment(form?.dateFrom).isBefore(value)) ||
    (name === "dateFrom" &&
      !!value &&
      !!form?.dateTo &&
      !moment(value).isBefore(form?.dateTo))
  ) {
    return {
      invalid: true,
      errorMessage: setErrorMessage("invalidRangeDate"),
    };
  }

  // default
  return {
    invalid: false,
    errorMessage: "",
  };
}
