import DialogWrapper from "../../theme/dialogs/DialogWrapper";
import { Box, Grid, Typography, useTheme } from "@material-ui/core";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import InfoIcon from "@material-ui/icons/Info";
import { useTranslation } from "react-i18next";
import { Button } from "../../theme/components/Button";
import SlicedAddress from "../../theme/components/SlicedAddress";

const SendConfirm = ({
  open,
  data: {
    amount = 0,
    address = "",
    total = 0,
    totalFee = 0,
    ticker = "",
    isInternal = false,
  },
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <DialogWrapper open={open} handleClose={onClose} title={t('Confirm send')}>
      {isInternal ? (
        <Typography
          variant={"body1"}
          style={{ marginBottom: 32, marginTop: 0, color: "#22dd22" }}>
          <InfoIcon
            style={{ fontSize: 16, marginTop: 2, marginRight: 4, verticalAlign: "text-top" }}
          />
          {t(
            "This transaction is internal. Therefore, no transaction fee is charged."
          )}
        </Typography>
      ) : (
        <Typography
          variant={"body1"}
          style={{ marginBottom: 32, color: "#AF994A" }}>
          <ReportProblemOutlinedIcon
            style={{ fontSize: 28, marginTop: -3, marginRight: 4, verticalAlign: "text-top" }}
          />
          {t("The cryptocurrency rate at the time of exchange may change")}
        </Typography>
      )}
      <Box alignItems={'center'} display="flex" alignSelf='center' justifyContent="center" marginBottom="24px">
          <img
            src={`https://cryptoneed.com/icons/${ticker}.svg`}
            alt={ticker}
            width={38}
            height={38}
            loading={"lazy"}
          />
        </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={5}>
          <Typography variant={"body1"}></Typography>
          {t("You send")}
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          style={{
            textAlign: "right",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}>
          <Typography variant={"body1"}></Typography>
          {amount.toString()} {ticker?.toUpperCase()}
        </Grid>
        <Grid item xs={12} sm={5}>
          <Typography variant={"body1"}></Typography>
          {t("Address")}
        </Grid>
        <Grid item xs={12} sm={7} style={{ textAlign: "right" }}>
          <Typography variant={"body1"}></Typography>
          <SlicedAddress address={address} />
        </Grid>
        {!isInternal && (
          <>
            <Grid item xs={12} sm={5}>
              <Typography variant={"body1"}></Typography>
              {t("Fee")}
            </Grid>
            <Grid item xs={12} sm={7} style={{ textAlign: "right" }}>
              <Typography variant={"body1"}></Typography>
              {totalFee} {ticker?.toUpperCase()}
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography variant={"body1"}></Typography>
              {t("Total")}
            </Grid>
            <Grid item xs={12} sm={7} style={{ textAlign: "right" }}>
              <Typography variant={"body1"}>
                {total} {ticker?.toUpperCase()}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
      <Grid container justifyContent='center'>
        <Button style={{ marginTop: 32, border: `1px solid ${theme.palette.primary.main}` }} onClick={onConfirm}>
          {t("Confirm")}
        </Button>
      </Grid>
    </DialogWrapper>
  );
};

export default SendConfirm;
